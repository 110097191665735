import React, { useState } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

import logo from '../images/logo.svg';
import mailIcon from '../images/mail-icon.svg';

const Navbar = styled.div`
  width: 100%;
  z-index: 9;
  position: ${(props) => (props.background ? 'relative' : 'absolute')};
  background: ${(props) => (props.background ? '#001336' : 'transparent')};
`;

const NavBarContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 0;
  justify-content: space-between;
  .logo {
    width: 300px;
    @media (max-width: 768px) {
      width: 200px;
    }
  }
  nav {
    display: flex;
    align-items: center;
    margin-left: auto;
    @media (max-width: 768px) {
      display: none;
    }
    img {
      margin-left: 2em;
      transition: 0.3s;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  ul {
    display: flex;
    list-style: none;
    li {
      padding: 0 2em;
      a {
        color: white;
        font-weight: 600;
        &:hover {
          color: #c25803;
        }
      }
    }
  }
`;

const Hamburger = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
  width: 60px;
  height: 45px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 10;
  margin: 0 0 0 auto;
  span {
    display: block;
    position: absolute;
    height: 6px;
    width: 100%;
    background: #d3531a;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(2) {
      top: 18px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(3) {
      top: 36px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }
  &.open {
    span {
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -3px;
        left: 8px;
      }
      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 39px;
        left: 8px;
      }
    }
  }
`;

const MobileNavigation = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #001336;
  z-index: 8;
  &.open {
    display: block;
  }
`;

const MobileNavigationInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  height: 100%;
  ul {
    list-style: none;
    font-size: 1.5em;
    text-align: center;
    a {
      color: white;
      transition: 0.3s;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  img {
    margin-top: 1em;
  }
`;

const Navigation = ({ showLogo, background }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {open ? (
        <Helmet
          bodyAttributes={{
            class: 'overflowHidden',
          }}
        />
      ) : null}
      <Navbar background={background}>
        <NavBarContainer className="container">
          {showLogo ? (
            <Link to="/">
              <img
                src={logo}
                alt="The Antique Marketing Company"
                className="logo"
              />
            </Link>
          ) : null}
          <nav>
            <ul>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              {/* <li>
            <Link to="/news">News</Link>
          </li> */}
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
            <a href="mailto:zara@theantiquemarketingcompany.co.uk?subject=More information required">
              <img src={mailIcon} alt="Contact The Antique Marketing Company" />
            </a>
          </nav>
          <Hamburger
            onClick={() => setOpen(!open)}
            className={open ? 'open' : null}
          >
            <span />
            <span />
            <span />
          </Hamburger>
        </NavBarContainer>
      </Navbar>
      <MobileNavigation className={open ? 'open' : null}>
        <MobileNavigationInner>
          <ul>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            {/* <li>
              <Link to="/news">News</Link>
            </li> */}
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <a href="mailto:zara@theantiquemarketingcompany.co.uk?subject=More information required">
              <img src={mailIcon} alt="Contact The Antique Marketing Company" />
            </a>
          </ul>
        </MobileNavigationInner>
      </MobileNavigation>
    </>
  );
};

export default Navigation;

Navigation.propTypes = {
  showLogo: PropTypes.bool,
  background: PropTypes.bool,
};
